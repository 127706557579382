import {takeEvery, put, call, select} from 'redux-saga/effects';
import {REQUESTED_SUBMIT_REQUEST, updateSessionId,} from '../action';
import {
  submitServiceRequest,
  createMemberSessionOpenApi,
  automaticDispatch
} from '../api';
import {datadogRum} from "@datadog/browser-rum";
import {
  getBusinessName,
  getCommonBenefits,
  getPartnerDetails,
  getServiceInfo,
  hasPepSessionSelector
} from "../selector";
import {
  submitServiceRequestFailed,
  submitServiceRequestSuccess
} from "../action/submitServiceRequest-action";
import {cleanServiceRequestPayload, isScheduleService} from '../utils/serviceRequestPayloadUtils'
import { TOW } from '../app-consts/appConstants';
import _isNil from 'lodash/isNil';



export function* submitServiceRequestSaga(action) {
  const commonBenefits = yield select(getCommonBenefits);
  const partnerDetails = yield select(getPartnerDetails);
  const serviceInfo = yield select(getServiceInfo);
  const hasPepSession = yield select(hasPepSessionSelector);

  const {partnerCode, omniStreamlined = "", experience, route} = partnerDetails;
  const {benefitsEnabled, ppuEnabled} = experience;
  const serviceType = serviceInfo.service;

  const payload = action.payload
  let sessionId = '';
  const values = new URLSearchParams(window.location.search);
  const urlSessionId = values.get('session');
  if (!payload.sessionId && !commonBenefits.noBenefits && payload.serviceRequest.loggedIn !== 'N' && urlSessionId) {
    datadogRum.addAction(`SessionID not available in request payload, Picked from url `, {
      displayCode: partnerDetails?.displayCode,
      serviceText: serviceInfo?.serviceText,
      payloadSessionId: payload?.sessionId,
      urlSessionId: urlSessionId
    });
    yield put(updateSessionId(urlSessionId))
    payload.sessionId = urlSessionId;
  }
  try {
    if (!payload.sessionId ||
      ( !partnerDetails.omniStreamlined && (benefitsEnabled && (commonBenefits.noBenefits === true || payload.serviceRequest.loggedIn === 'N') ||
      (hasPepSession && serviceInfo.serviceText !== TOW)))
    ) {
      datadogRum.addAction(`Submit Rescue PPU`, {
        displayCode: partnerDetails?.displayCode,
        sessionId: payload?.sessionId,
        omniStreamlined: !!partnerDetails?.omniStreamlined,
        benefitsEnabled,
        noBenefits: commonBenefits?.noBenefits,
        loggedIn: payload?.serviceRequest?.loggedIn,
        hasPepSession,
        serviceText: serviceInfo?.serviceText,
      });
      const memberId = (commonBenefits.noBenefits === true || payload.serviceRequest.loggedIn === 'N') ? '' : commonBenefits.memberId;
      const type = partnerDetails.ppuSessionType;
      const appId = payload.serviceRequest.appId;

      const latitude = payload.serviceRequest.disablementAddress.latitude;
      const longitude = payload.serviceRequest.disablementAddress.longitude;

      const options = {
        memberId,
        type,
        partnerCode,
        appId,
        latitude,
        longitude,
        flippedToPpu: ppuEnabled
      };
      let sessionResponse;
      try {
        sessionResponse = yield call(createMemberSessionOpenApi, route, options);
      } catch (error) {
        let errorToDisplay = error.response?.data?.message || error.response?.data;
        datadogRum.addError(new Error(`Submit Service Request not called due to Session API failed with error: ${JSON.stringify(errorToDisplay)}, Action: ${JSON.stringify(action)}`));
        yield put(submitServiceRequestFailed());
      }
      sessionId = sessionResponse.data.id
      if(sessionId) yield put(updateSessionId(sessionId))
      payload.sessionId = sessionResponse.data.id
    }

    cleanServiceRequestPayload(serviceInfo.id, payload.serviceRequest)
    let response = yield call(submitServiceRequest, payload, partnerCode, serviceType, partnerDetails.experience, hasPepSession, omniStreamlined);

    if (response.status === 200 && isScheduleService(payload)) {
      const requestScheduleDateTime = payload?.serviceRequest?.additionalFields?.[0]?.find(
        ({ key = "" }) => key === "requestscheduledatetime"
      )
      datadogRum.addAction("Scheduled Service Submission", { 
        displayCode: partnerDetails?.displayCode,
        currentDate: new Date().toLocaleString("en-US"), 
        requestScheduleDateTime
      });
    };
    yield put(submitServiceRequestSuccess(response.data));
  } catch (error) {
    let errorToDisplay = error.response?.data?.error?.errors[0] || error.response?.data;
    errorToDisplay = _isNil(errorToDisplay) ? error?.stack : JSON.stringify(errorToDisplay);
    datadogRum.addError(new Error(`Submit Service Request API failed with error: ${errorToDisplay}, Action: ${JSON.stringify(action)}`));
    yield put(submitServiceRequestFailed());
  }
}

export function* watchSubmitRequestSaga() {
  yield takeEvery(REQUESTED_SUBMIT_REQUEST, submitServiceRequestSaga);
}
