import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from "react-router-dom";
import Form from '../../components/form';
import FormField from '../../components/form-field';
import EpcsIframe from '../../components/epcs';
import PaymentCards from '../../components/svg/PaymentCards/PaymentCards';
import Dropdown from "../../components/dropdown";
import Icon from '../../components/icon';
import {
  requestEpcsInitialize,
  updatedEpcsStatus,
  updateEpcsStatusIncomplete,
  updateFirstName,
  updateLastName,
  updateDriverList,
  updateSmsOptIn,
  updateEmail,
  updateContactNumber,
  updateCCardInfo,
  updateServiceTransition,
  updateOperatorId
} from '../../action';
import editPhone from './../../utils/editPhone';
import FormOption from '../../components/form-option';
import {sendJacadaRequest} from '../../api';
import CONSTANTS, { EPCS_STATUS_SUCCESS } from "../../app-consts/appConstants";
import nameHandler from '../../utils/nameHandler';
import Spinner from "../../components/spinner";
import {datadogRum} from '@datadog/browser-rum';
import {ErrorSource} from '@datadog/browser-core';
import {getUniqueDriverList, doesReduxDriverListHaveBenefitsOperators} from '../../utils/getUniqueDriverList';
import FormValidators from "../../utilities/form-validators";
import {generatePath} from '../../utilities/RedirectUtils'
import {useLocale} from "../../contexts/LocaleContext";
import {TRANSLATION_CONSTANTS} from "../../app-consts/translations";
import { Button,
  Icon as MeshIcon,
  Type,
  Utility
} from 'mesh-component-library';
import { PlusIcon } from "mesh-icon-library";
import {getDriverList, getServiceInfo, hasPepSessionSelector} from '../../selector';
import { UNABLE_TO_COMPLETE_SERVICE } from '../../app-consts/errorMessageConstants';

const Payment = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const epcsInitialize = useSelector(state => state.epcsInitialize);
  const epcsStatus = useSelector(state => state.epcsStatus);
  const ppuEnabled = useSelector(state => state.partnerDetails.partnerDetails.experience?.ppuEnabled);
  const benefitsEnabled = useSelector(state => state.partnerDetails.partnerDetails.experience?.benefitsEnabled);
  const commonBenefits = useSelector(state => state.commonBenefits);
  const serviceRequest = useSelector(state => state.serviceRequestPayload.serviceRequest);
  const callId = useSelector(state => state.serviceRequestPayload.sessionId);
  const driverListObj = useSelector(getDriverList);
  const hasPepSession = useSelector(hasPepSessionSelector);
  const serviceInfo = useSelector(getServiceInfo);

  const [showSpinner, setShowSpinner] = useState(false);
  const [name, setName] = useState('');
  const [nameHasError, setNameHasError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(serviceRequest?.contactNumber);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [emailHasError, setEmailHasError] = useState(false);
  const [billingZipCode, setBillingZipCode] = useState('');
  const [billingZipCodeHasError, setBillingZipCodeHasError] = useState(false);
  const [driverSelectHasError, setDriverSelectHasError] = useState(false);
  const [driverList, setDriverList] = useState([]);
  const [smsOptIn, setSmsOpt] = useState(true);
  const [epcsIframeComponent, setEpcsIframeComponent] = useState();
  const [selectedDriverPosition,  setSelectedDriverPosition] = useState(0);
  const getTranslatedText = useLocale();

  const userIsPpu = serviceRequest.ppuFlag === 'Y' && ppuEnabled;
  const addAMember = commonBenefits?.experience?.addAMember;
  const [isAddContactClicked, setAddContactClicked] = useState(false);

  useEffect(() => {
    document.title = 'Payment | Roadside Assistance';
    setPreExistingDataInState();
    const shouldEpcsInitialize = !shouldRemoveExtraContactInfo && userIsPpu;
    if (shouldEpcsInitialize && !epcsInitialize.success) {
      dispatch(requestEpcsInitialize());
    }
  }, []);

  const setPreExistingDataInState = () => {
    let filteredDriverList;
    if (doesReduxDriverListHaveBenefitsOperators(commonBenefits.operators, driverListObj?.driverList)) {
      filteredDriverList = driverListObj?.driverList;
    } else {
      filteredDriverList = getUniqueDriverList({drivers: commonBenefits.operators});
    }
    let name;
    if (filteredDriverList?.length === 1) {
      name = filteredDriverList[0].value;
    } else {
      name = serviceRequest.firstName + " " + serviceRequest.lastName;
    }

    if(filteredDriverList?.length) setDriverList(filteredDriverList);

    setName(name);
    setPhoneNumber(editPhone(serviceRequest.contactNumber));
    setEmailAddress(serviceRequest.email);
    setSmsOpt(serviceRequest.smsOptIn === "Y");
    setBillingZipCode(epcsStatus.request?.billingZip)
  };

  useEffect(() => {
    redirectOnEpcsResponse();
  }, [epcsStatus]);
  const redirectOnEpcsResponse = () => {
    setShowSpinner(false);
    if (epcsStatus.success && epcsStatus.response?.maskedCardNumber && !epcsStatus.completed) {
      dispatch(updatedEpcsStatus());
      datadogRum.addAction('Payment or Contact Information Confirmed')
      history.push(generatePath('location'));
    } else if (epcsStatus.error) {
      datadogRum.addError(new Error(`Epcs failure: ${epcsStatus.error}`), undefined, ErrorSource.CUSTOM);
      history.push(generatePath('sitedown'));
    } else if (epcsStatus?.retry && epcsStatus.retry >= 3) {
      datadogRum.addError(new Error(`Payment Status failure: ${epcsStatus?.response?.paymentStatus}`), undefined, ErrorSource.CUSTOM);
      dispatch(
        updateServiceTransition({
          errorMessageContent: UNABLE_TO_COMPLETE_SERVICE,
        })
      );
      history.push(generatePath("ServiceTransition"));
    }
  };

  useEffect(() => {
    if (epcsInitialize.error) {
      datadogRum.addError(new Error(`Epcs Initialize failure: ${epcsInitialize.error}`), undefined, ErrorSource.CUSTOM);
      history.push(generatePath('sitedown'));
    }
  }, [epcsInitialize])

  const emailOnChange = ({target: {value, required}}) => {
    setEmailAddress(value);

    if (required || emailHasError) {
      setEmailHasError(!isEmailValid(value));
    }
  };

  const isEmailValid = (emailAddress) => {
    const regex = /(\w(=?@)\w+\.{1}[a-zA-Z]{2,})/i;
    return (!userIsPpu && !emailAddress.length) || regex.test(emailAddress);
  };

  const validateEmail = () => {
    setEmailHasError(!isEmailValid(emailAddress));
  };

  const nameOnChange = ({target: {value}}) => {
    setName(value.trim());

    if (nameHasError) {
      setNameHasError(!isNameValid(value));
    }
  };

  const isNameValid = (name) => {
    const regex = /\w+\s{1}\w+/i;
    return regex.test(name);
  };

  const validateName = () => {
    setNameHasError(!isNameValid(name));
  };

  const phoneOnChange = ({target: {value}}) => {
    const editedPhoneNumber = editPhone(value);
    setPhoneNumber(editedPhoneNumber);
    return phoneNumber
  };

  useEffect(() => {
    setIsValidPhoneNumber(/\d{3}-\d{3}-\d{4}/.test(JSON.stringify(phoneNumber)));
  }, [phoneNumber])

  const billingZipCodeOnChange = ({target: {value}}) => {
    setBillingZipCode(value.slice(0, 5));

    if (billingZipCodeHasError) {
      setBillingZipCodeHasError(!isZipValid(value));
    }
  };

  const isZipValid = (zip) => {
    const regex = /^\d{5}(-\d{4})?$/;
    return regex.test(zip);
  };

  const validateBillingZipCode = () => {
    setBillingZipCodeHasError(!isZipValid(billingZipCode));
  };

  const handleZipBackspace = (e) => {
    const {keyCode, target} = e;
    let isNotDeleting = keyCode !== CONSTANTS.DELETE && keyCode !== CONSTANTS.BACKSPACE;
    let zipIsTooLong = target.value?.toString().length >= target.maxLength;
    let inputIsNotANumber = ![0,1,2,3,4,5,6,7,8,9].includes(Number(e.key));

    if (isNotDeleting && (zipIsTooLong || inputIsNotANumber)){
      e.preventDefault();
    }
  };

  const selectedDriverOnChange = ({target}) => {
    setDriverSelectHasError(!target.value);
    setName(target.value);
    setSelectedDriverPosition(target.selectedIndex-1)
  };

  const smsHandler = () => {
    setSmsOpt(!smsOptIn);
  };

  const updateUserInfo = () => {
    let firstName, lastName, operatorId;

    if(driverList?.length && !isAddContactClicked) {
      firstName = driverList[selectedDriverPosition].firstname
      lastName = driverList[selectedDriverPosition].lastname
      operatorId = driverList[selectedDriverPosition]?.operatorid
    } else {
      firstName = nameHandler(name).firstName;
      lastName = nameHandler(name).lastName;
    }

    dispatch(updateFirstName(firstName));
    dispatch(updateLastName(lastName));
    if (operatorId) {
      dispatch(updateOperatorId(operatorId));
    }
    dispatch(updateSmsOptIn(smsOptIn ? "Y" : "N"));
    dispatch(updateEmail(emailAddress));
    dispatch(updateContactNumber(phoneNumber));
    if (ppuEnabled && serviceRequest.ppuFlag === 'Y') {
      dispatch(updateCCardInfo({billingZipCode}))
    }
  };

  const sendSubmitEventToJacada = (smsOptIn, firstName, lastName, emailAddress, phone) => {
    let eventsData = [];
    eventsData.push(
      {'name': 'sms_feedbck_consnt_ind', 'value': smsOptIn},
      {'name': 'first_name', 'value': firstName},
      {'name': 'last_name', 'value': lastName},
      {'name': 'smtp_addr', 'value': emailAddress},
      {'name': 'phone_nbr', 'value': phone}
    );

    let jacadaRequestData = {
      'externalSystemId': localStorage.getItem('conversationId'),
      'events': eventsData
    };
    sendJacadaRequest(jacadaRequestData);
  };

  const handleEpcsValidationError = () => {
    setShowSpinner(false);
  };

  const handleSubmit = () => {
    setShowSpinner(true);
    const {firstName, lastName} = nameHandler(name);
    sendSubmitEventToJacada(smsOptIn, firstName, lastName, emailAddress, phoneNumber);
    updateUserInfo();
    if (isAddContactClicked) {
      let finalDriverList = getUniqueDriverList({drivers: driverList, newDriverFirstName: firstName, newDriverLastName: lastName});
      dispatch(updateDriverList(finalDriverList))
    }
    if (userIsPpu) {
      if (!epcsStatus.response?.maskedCardNumber) {
        const amount = serviceRequest?.CCardInfo?.transactionAmount || "0";
        const serviceType = serviceInfo?.serviceType || "";
        epcsIframeComponent?.handleEditCCSubmit(billingZipCode, serviceType, parseFloat(amount), callId);
      }
      dispatch(updateEpcsStatusIncomplete());
    } else {
      dispatch(updatedEpcsStatus());
      history.push(generatePath('location'));
    }
  };

  const renderCreditCardFormFields = () => {
    return (
      <div className="contact-payment-contain">
        <div className="contact-payment-header">
          <h4 className="contact-payment-titles">{getTranslatedText(TRANSLATION_CONSTANTS.PAYMENT_INFO)}</h4>
          <div className="payment-cards-container">
            <PaymentCards/>
          </div>
        </div>
        <div className="l-grid__col epcsContain">
          {
            epcsInitialize.success ?
              <EpcsIframe
                tabIndex="4"
                scrolling="no"
                epcsInitialize={epcsInitialize.initialize}
                onValidationError={handleEpcsValidationError}
                onRef={(i) => setEpcsIframeComponent(i)}
              />
              :
              null
          }
          {(!!epcsStatus?.response?.paymentStatus && epcsStatus?.response?.paymentStatus !== EPCS_STATUS_SUCCESS) &&
          <Utility.Div utils={{
            vrBottom:"md"
          }}>
            <Type variant="critical" weight="bold">
              {getTranslatedText(TRANSLATION_CONSTANTS.CARD_RETRY_MESSAGE)}
            </Type>
          </Utility.Div>}
          <div className="splitPayment">
            <FormField
              errorText={getTranslatedText(TRANSLATION_CONSTANTS.ENTER_DIGIT_ZIP_CODE)}
              id="billingZip"
              name="billingZip"
              label={getTranslatedText(TRANSLATION_CONSTANTS.BILLING_ZIP)}
              x-autocompletetype="postal-code"
              autoComplete="postal-code"
              type="number"
              maxLength={5}
              value={billingZipCode}
              onKeyPress={(e) => handleZipBackspace(e)}
              onChange={billingZipCodeOnChange}
              onBlur={validateBillingZipCode}
              hasError={billingZipCodeHasError}
              tabIndex="5"
              required
            />
          </div>
        </div>
      </div>
    );
  };

  const renderSubmitButton = () => {
    let buttonIsDisabled;
    if (userIsPpu && ppuEnabled) {
      buttonIsDisabled = !name || nameHasError || !phoneNumber || !isValidPhoneNumber || !emailAddress || emailHasError || !billingZipCode || billingZipCodeHasError;
      if(!!epcsStatus?.response?.paymentStatus) {
        buttonIsDisabled = epcsStatus.response.paymentStatus !== EPCS_STATUS_SUCCESS
      }
    } else if (benefitsEnabled) {
      buttonIsDisabled = !name || nameHasError || !phoneNumber || !isValidPhoneNumber || emailHasError || (driverSelectHasError && !isAddContactClicked);
    } else if (hasPepSession || (!ppuEnabled && !benefitsEnabled)) {
      buttonIsDisabled = !name || nameHasError || !phoneNumber || !isValidPhoneNumber || emailHasError;
    }
    return (
      <>
        <div className="payment-meta text_left mt-3">
          <label dangerouslySetInnerHTML={{__html: getTranslatedText(TRANSLATION_CONSTANTS.COMPLIANCE_CONFIRMATION)}} data-testid="compliance-message"></label>
        </div>
        <div className="c-modal-dialog__footer--payment hasBenefits u-vr-2-top">
          <Button
            size='lg'
            hasUpgrade
            id="payment-confirm"
            data-testid="ppu-add-payment-button"
            type="submit"
            disabled={buttonIsDisabled}
            onClick={(event, fields) => handleSubmit(event, fields)}
            utils={{
              fullWidth: true
            }}>
            {getTranslatedText(TRANSLATION_CONSTANTS.CONFIRM)}
          </Button>
        </div>
      </>
    );
  };
  const shouldRemoveExtraContactInfo = !ppuEnabled && !benefitsEnabled;

  const addContact = () => {
    setAddContactClicked(true);
    setName('');
  };

  return (
    <div role="main">

      {showSpinner && <Spinner/>}

      <div className="paymentModalHeader c-modal-dialog__header">
        <button onClick={() => history.push(generatePath('location'))}
                className={`c-btn c-btn--icon u-float--right`}
                aria-label="Close Modal">
          <Icon icon="cross" color="brand" size={1} className="icon-rotation"/>
        </button>
        <h3 id="payment-header" className="c-modal__title">
          {(commonBenefits.operators?.length && !isAddContactClicked) ? getTranslatedText(TRANSLATION_CONSTANTS.WHICE_DRIVER_NEEDS_HELP) : getTranslatedText(TRANSLATION_CONSTANTS.ADD_CONTACT)}
        </h3>
      </div>

      <Form className="contactPayment contactPayment--standalone"
            validateOnBlur={true}>
        <div className="l-grid">
          <div className="l-grid__col">
            <div className="c-container--contactInfo">
              {(commonBenefits.operators?.length && !isAddContactClicked) ?
                <Dropdown
                  id="driver"
                  name="driver"
                  label={getTranslatedText(TRANSLATION_CONSTANTS.DRIVER)}
                  className="selectDriver"
                  boldArrow={true}
                  hasError={driverSelectHasError}
                  errorText={getTranslatedText(TRANSLATION_CONSTANTS.SELECT_A_DRIVER)}
                  value={name}
                  onChange={selectedDriverOnChange}
                  defaultOption={{value: "", text: getTranslatedText(TRANSLATION_CONSTANTS.SELECT_ONE)}}
                  options={driverList}
                />
                :
                <FormField
                  errorText={getTranslatedText(TRANSLATION_CONSTANTS.ENTER_FIRST_LAST_NAME)}
                  label={getTranslatedText(TRANSLATION_CONSTANTS.NAME)}
                  id="name"
                  className="name"
                  minLength={3}
                  name="name"
                  x-autocompletetype="name-full"
                  autoComplete="name"
                  value={name}
                  onChange={nameOnChange}
                  onBlur={validateName}
                  hasError={nameHasError}
                  tabIndex="1"
                  required/>
              }
              <FormField
                errorText={getTranslatedText(TRANSLATION_CONSTANTS.ENTER_VALID_EMAIL_ADDRESS)}
                label={getTranslatedText(TRANSLATION_CONSTANTS.EMAIL)}
                id="email"
                name="email"
                type="email"
                x-autocompletetype="email"
                autoComplete="email"
                value={emailAddress}
                onChange={emailOnChange}
                onBlur={validateEmail}
                hasError={emailHasError}
                tabIndex="2"
                required={userIsPpu || !shouldRemoveExtraContactInfo}/>

              <FormField
                id="phone"
                errorText={getTranslatedText(TRANSLATION_CONSTANTS.ENTER_PHONE_NUMBER)}
                name="phone"
                label={getTranslatedText(TRANSLATION_CONSTANTS.PHONE_NUMBER)}
                type="tel"
                onChange={phoneOnChange}
                value={phoneNumber}
                validators={[FormValidators.phoneNumberValidator]}
                x-autocompletetype="phone-full"
                autoComplete="tel"
                required
              />
              <div>
                <FormOption
                  data-dd-privacy="allow"
                  onChange={smsHandler}
                  className="sms-check"
                  id="sms-check"
                  label={getTranslatedText(TRANSLATION_CONSTANTS.SEND_TEXT_MESSAGES)}
                  name="sms-check"
                  checked={smsOptIn}
                  required={true}
                />
              </div>
              { addAMember && commonBenefits.operators?.length > 0 && !isAddContactClicked &&
                <Button
                  size='md'
                  hasUpgrade
                  isHollow
                  shape="square"
                  onClick={addContact}
                  utils={{
                    vrTop: "md"
                  }}
                >
                  <MeshIcon icon={PlusIcon} />{" "}
                  <Type variant="darker" weight="bold">
                    {getTranslatedText(TRANSLATION_CONSTANTS.ADD_CONTACT)}
                  </Type>
                </Button>
              }
            </div>
          </div>

          {shouldRemoveExtraContactInfo ? null : (userIsPpu && renderCreditCardFormFields())}
          {renderSubmitButton()}

        </div>
      </Form>
    </div>
  );
};

export default Payment;
